import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Layout from 'src/common/components/Layout';
import AboutUs from 'src/common/components/AboutUs';
import GoogleMap from 'src/support/components/GoogleMap';

import ProductionList from 'src/common/components/ProductionList';
import Product from 'src/common/components/Product';
import Cart from 'src/common/components/Cart';
import { getPrices, setProducts } from 'src/common/redux/actions';
import OurStoryScreen from 'src/common/components/OurStoryScreen';
import Landing from 'src/landing/components/Landing';

const Routes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      await dispatch(getPrices());
      dispatch(setProducts());
    }

    fetchData();
  }, [dispatch]);

  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Landing />
          <AboutUs />
          <ProductionList />
        </Route>
        <Route path="/product/:category/:id" exact component={Product}></Route>
        <Route path="/cart" component={Cart} exact></Route>
        <Route path="/ourstory" component={OurStoryScreen} exact></Route>
      </Switch>
    </Layout>
  );
};

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
