import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import ProductPreview from 'src/common/components/ProductPreview';

import './index.css';

export default ({ products }) => {
  const [loading, setLoading] = useState(true);
  const [preparedProducts, setPrepearedProducts] = useState([]);

  useEffect(() => {
    if (products) {
      var preparedProducts = [];
      for (const category of products) {
        for (const product of category.products) {
          product.categoryId = category.id;
          preparedProducts.push(product);
        }
      }
      setPrepearedProducts(preparedProducts);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [products]);
  return (
    <div className="category-list">
      <h1 id="production-list" className="category-list__title">
        Что мы производим
      </h1>

      <Spin spinning={loading} tip="Загрузка товаров...">
        <div className="product-preview__list" id="product-preview__list">
          {(preparedProducts || []).map(product => {
            return <ProductPreview {...{ product }} key={product.id} />;
          })}
        </div>
      </Spin>

      <div className="aboutus__text-block__container aboutus__text-block__container--five">
        <p className="aboutus__text-block aboutus__text-block--five">
          Главный принцип нашей работы — {''}
          <span style={{ fontWeight: '600' }}>строгий контроль</span> на каждом
          этапе производства.
        </p>
      </div>
      <div className="aboutus__text-block__container aboutus__text-block__container--last">
        <div className="aboutus__text-block aboutus__text-block--last">
          За <span class="year-highlight">2024</span> год мы:
          <ul>
            <li>переработали более 10 тысяч тонн пшеницы;</li>
            <li>отгрузили более 7 тысяч заказов;</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
