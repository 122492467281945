import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logo_black from 'src/assets/icons/logo_black.png';

import './index.css';
import { ShoppingCartOutlined } from '@ant-design/icons';

import Contacts from 'src/support/components/Contacts';
import FastContactBlock from 'src/support/components/FastContactBlock';

export default ({ children, cartIndicator }) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  const [isMobileCart, setIsMobileCart] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileCart(location.pathname === '/cart' && window.innerWidth < 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);

  return (
    <div
      id={`${isLandingPage ? 'main' : 'product'}`}
      className={`layout ${isLandingPage ? '' : 'layout--without_back'}`}
    >
      <Helmet>
        <title>Мука пшеничная в Краснодарском крае! - Динская Мельница!</title>
        <meta
          name="description"
          content="Оптовые поставки пшеничной муки! Собственное производство! 21 год на рынке! Подберём муку под ваше производство! Три сорта хлебопекарной муки!"
        />
      </Helmet>

      <div className={`layout__header ${isMobileCart ? 'hide' : ''}`}>
        <Link to="/#main" className="homebutton-container">
          <img src={logo_black} alt="logo" className="header_logo" />
        </Link>

        <div className="layout__links-container">
          <Link to="/#production-list" className="layout__link">
            <h3 className="layout__link__title">Продукция</h3>
          </Link>

          <Link to="/ourstory#ourstory" className="layout__link show">
            <h3 className="layout__link__title">О нас</h3>
          </Link>
          <Link to="/#contacts" className="layout__link">
            <h3 className="layout__link__title">Контакты</h3>
          </Link>
        </div>
        <Link to="/cart#cart" className="layout__link--cart">
          <ShoppingCartOutlined className="layout__header__svg" />
          {cartIndicator > 0 && (
            <div>
              <p className="layout__cart-indicator">{cartIndicator}</p>
            </div>
          )}
        </Link>
      </div>

      {children}

      {(location.pathname !== '/cart' || window.innerWidth < 600) && (
        <div id="contacts">
          <Contacts />
          <FastContactBlock />
        </div>
      )}
    </div>
  );
};
