import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import ProductPreview from 'src/common/components/ProductPreview';

import './index.css';

export default ({ products }) => {
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const updateProductList = () => {
      if (
        products &&
        products.length > 0 &&
        products[0].products &&
        products[0].products.length > 0
      ) {
        setCategory(products[0]);

        const isWideScreen = window.innerWidth > 600;

        const displayedProducts = isWideScreen
          ? products[0].products.slice(0, 3)
          : products[0].products.slice(0, 1);

        setProductList(displayedProducts);
      } else {
        setCategory(null);
        setProductList([]);
      }
    };

    updateProductList();

    window.addEventListener('resize', updateProductList);

    return () => window.removeEventListener('resize', updateProductList);
  }, [products]);

  useEffect(() => {
    if (category && productList.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [productList, category]);

  return (
    <div id="aboutus" className="aboutus">
      <div className="aboutus__text-block__container aboutus__text-block__container--one">
        <p className="aboutus__text-block aboutus__text-block--one">
          <span className="line-accent">Кажется невозможным,</span> но всё дело
          в опыте нашей команды и лучшей пшенице.
        </p>
      </div>
      <Spin spinning={loading} tip="Загрузка...">
        {productList.length > 0 && category ? (
          <div className="product-preview__list">
            {productList.map(product => (
              <ProductPreview {...{ product, category }} key={product.id} />
            ))}
          </div>
        ) : (
          <p>Данные недоступны.</p>
        )}
      </Spin>

      <div className="aboutus__text-block__container aboutus__text-block__container--three">
        <p className="aboutus__text-block aboutus__text-block--three">
          — Так, мы <span style={{ fontWeight: '600' }}> создаем муку, </span>{' '}
          которая становится основой ваших достижений —
        </p>
      </div>
    </div>
  );
};
