import createReducer from 'src/utils/createReducer';

import { LOAD_PRODUCT } from './types';
import { SET_VOLUME } from './types';
import { ADD_TO_CART } from './types';
import { DELETE_FROM_CART } from './types';
import { RESET_CART } from './types';
import { SET_PRICES } from './types';
import { SET_PRODUCTS } from './types';
import productsData from 'src/data/productsData.js';

const INITIAL_STATE = {
  product: false,
  cart: [],
  cartIndicator: 0,
  selectedVolume: false,
  prices: false,
  products: null,
};

function addToCart(cart, action) {
  var neededIndex = false;
  cart.forEach((product, index) => {
    var title = product.title + product.volume;
    if (title === action.payload.title + action.payload.volume) {
      neededIndex = String(index);
    }
  });

  if (neededIndex) {
    cart[neededIndex].weight =
      +cart[neededIndex].weight + +action.payload.weight;
    cart[neededIndex].pieces =
      +cart[neededIndex].pieces + +action.payload.pieces;
  } else {
    cart.push({
      id: action.payload.id,
      title: action.payload.title,
      product: action.payload.product,
      weight: action.payload.weight,
      price: action.payload.price,
      volume: action.payload.volume,
      pieces: action.payload.pieces,
      link: action.payload.link,
    });
  }

  return cart;
}

function prepareProducts(products, prices) {
  if (!prices || !prices.length) return;
  const priceMap = prices.reduce((acc, data) => {
    acc[data._id] = data.price;
    return acc;
  }, {});

  const updatedProducts = products.map(category => ({
    ...category,
    products: category.products.map(product => ({
      ...product,
      volumes: product.volumes.map(volume => ({
        ...volume,
        price: priceMap[volume._id] || volume.price,
      })),
    })),
  }));

  return updatedProducts;
}
function deleteFromCart(cart, action) {
  return cart.filter(product => {
    return product.id !== action.payload.id;
  });
}

export default createReducer(INITIAL_STATE, {
  [LOAD_PRODUCT]: (state, action) => ({
    ...state,
    product: action.payload,
  }),
  [SET_VOLUME]: (state, action) => ({
    ...state,
    selectedVolume: action.payload,
  }),
  [ADD_TO_CART]: (state, action) => ({
    ...state,
    cart: addToCart(state.cart, action),
    cartIndicator: state.cart.length,
  }),
  [DELETE_FROM_CART]: (state, action) => ({
    ...state,
    cart: deleteFromCart(state.cart, action),
    cartIndicator: state.cart.length - 1,
  }),
  [RESET_CART]: () => ({
    ...INITIAL_STATE,
    cart: [],
    cartIndicator: 0,
  }),
  [SET_PRICES]: (state, action) => ({
    ...state,
    prices: action.prices,
  }),
  [SET_PRODUCTS]: state => ({
    ...state,
    products: prepareProducts(productsData, state.prices),
  }),
});
