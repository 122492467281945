import React from 'react';
import MessengersBlock from 'src/landing/components/MessengersBlock';

import './index.css';

export default () => {
  return (
    <div className="landing">
      <p className="landing__text-block">
        <p className="landing__text-block--highlighted">24 года производства</p>
        — простой секрет нашего качества —
      </p>

      <MessengersBlock />
    </div>
  );
};
