import React from 'react';

import './index.css';

export default () => {
  return (
    <div id="aboutus" className="ourstory-screen">
      <div className="ourstory-screen__text__container">
        <h4 className="ourstory-screen__title"> О компании</h4>
        <p className="ourstory-screen__text-block">
          Наша компания начала свой путь 24 года назад. То, что начиналось как
          небольшое предприятие, постепенно выросло в современное производство,
          способное выпускать в разы больше продукции. Сегодня наше предприятие
          одно из ведущих по производству пшеничной муки и других продуктов
          мукомольного производства.
        </p>

        <h4 className="ourstory-screen__subtitle">Производственные мощности</h4>
        <p className="ourstory-screen__text-block">
          Мощность нашего производства составляет 1500 тонн продукции в месяц,
          поэтому мы справляемся как с небольшими заказами, так и с крупными
          оптовыми поставками. Мы поставляем нашу продукцию пекарням и
          кондитерским производствам как в России, так и в соседние страны.
        </p>

        <h4 className="ourstory-screen__subtitle">Сырье и контроль качества</h4>
        <p className="ourstory-screen__text-block">
          Наше производство находится в Краснодарском крае, где выращивается
          лучшая пшеница для производства муки. Мы тщательно подходим к выбору
          сырья: каждая партия пшеницы проходит лабораторные испытания перед
          закупкой. Такой же строгий контроль качества сохраняется на всех
          этапах производства: готовая продукция проходит обязательное
          тестирование перед отгрузкой клиентам. Вся наша продукция
          сертифицирована и соответствует стандартам EAC – единой системе
          качества Евразийского союза.
        </p>

        <h4 className="ourstory-screen__subtitle">Наша команда</h4>
        <p className="ourstory-screen__text-block">
          За годы работы мы собрали команду настоящих профессионалов, преданных
          своему делу. От поля до производства — каждый этап создания муки
          требует слаженной работы десятков людей. Фермеры и комбайнеры,
          водители, лаборанты, технологи и работники производства — именно
          благодаря ответственной работе каждого члена нашей большой команды мы
          производим муку первоклассного качества.
        </p>
      </div>
    </div>
  );
};
