import React from 'react';
import telegram from 'src/assets/icons/telegram.png';
import whatsapp from 'src/assets/icons/whatsapp.png';
import './index.css';

export default () => {
  return (
    <div className="fastcontact">
      <div className="fastcontact__item">
        <a href="https://t.me/Din_Mel" target="blank">
          <img src={telegram} alt="telegram" className="fastcontact__icon" />
        </a>
      </div>

      <div className="fastcontact__item">
        <a href="https://wa.me/79385156161" target="blank">
          <img src={whatsapp} alt="whatsapp" className="fastcontact__icon" />
        </a>
      </div>
    </div>
  );
};
