import React from 'react';
import Pointer from 'src/assets/img/main/pointer.svg';

import './index.css';

export default () => {
  return (
    <div className="contacts__container">
      {window.innerWidth > 600 && <Pointer className="contacts__pointer" />}
      <div className="contacts__contact-block">
        <h4 className="contacts__contact-block__title"> Контакты </h4>
        <br />
        <br />
        <br />
        <div className="contacts__list">
          <div className="contacts__contact">
            <h5 className="contact__title">Отдел продаж</h5>
            <a className="contact__link" href="tel:+79385156161">
              <p className="contact__info">+7 (938) 515-61-61</p>
            </a>
          </div>

          <div className="contacts__contact">
            <h5 className="contact__title"> Адрес</h5>
            <a
              className="contact__link"
              href="https://www.google.com/maps/place/%D0%94%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F+%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D0%B0/@45.054052,39.9330624,8.8z/data=!4m5!3m4!1s0x0:0x667e72f01d0496b6!8m2!3d45.2251052!4d39.1971512"
            >
              <p className="contact__info">
                Краснодарский край, <br />
                ст. Динская, ул.Крайняя 12а
              </p>
            </a>
          </div>
          <div className="contacts__contact">
            <h5 className="contact__title"> E-mail</h5>
            <a className="contact__link" href="mailto:din.melnica@gmail.com">
              <p className="contact__info">din.melnica@gmail.com</p>
            </a>
          </div>
          <div className="contacts__contact">
            <h5 className="contact__title">Бухгалтерия</h5>
            <a className="contact__link" href="tel:+78616251640">
              <p className="contact__info"> 8 (86162) 5-16-40</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
