import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import './index.css';

export default ({ product, loadProduct, setVolume, saleSlogan }) => {
  return (
    <div className="product-preview__container">
      <div
        className="product-preview"
        onClick={() => {
          loadProduct(product);
          setVolume(product.volumes[product.volumes.length - 1]);
        }}
      >
        <Link to={`/product/${product.categoryId}/${product.id}#product`}>
          <img
            className="product-preview__icon"
            src={product.thumbnail}
            alt="icon"
          />

          <div className="product-preview__info-block">
            <div className="product-preview__info-block__left-section">
              <span className="product-preview__short-category">
                {product.category}
              </span>

              <h5 className="product-preview__title">{product.title}</h5>
            </div>

            <div className="product-preview__info-block__right-section">
              <div className="product-preview__price-block">
                <h3 className="product-preview__price-block__price-title">
                  от:
                </h3>
                &nbsp;
                <h3 className="product-preview__price-block__price">
                  {product.volumes[product.volumes.length - 1].price}
                </h3>
                <h3 className="product-preview__price-block__ruble-symbol">
                  &nbsp;
                  <span> &#8381;</span>
                  &nbsp;
                </h3>
                <h3 className="product-preview__price-block__price-title">
                  за КГ
                </h3>
              </div>
              {(saleSlogan || product.saleSlogan) && (
                <span className="product-preview__price-block__sale-slogan">
                  • {saleSlogan || product.saleSlogan || ''}
                </span>
              )}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
